import { useEffect, useRef, useState } from "react";
import { Howl, Howler } from "howler";
import { PauseIcon, StartIcon } from "cssReset/icon";

interface DataProps {
  audioPath: string;
  clickedSeconds?: number; // 선택된 항목 정보
}

const MeasureRecordResultAudio = ({ audioPath, clickedSeconds }: DataProps) => {
  const audioRef = useRef<Howl | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [soundId, setSoundId] = useState<number | null>(null);
  const [pendingSeek, setPendingSeek] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  // 🔄 updateProgress 중복 방지용 ref 추가
  const rafIdRef = useRef<number | null>(null);
  // 전역 변수로 마지막 위치 저장
  const latestDragTimeRef = useRef(0);

  useEffect(() => {
    if (clickedSeconds === undefined || clickedSeconds === null) return;

    const sound = audioRef.current;

    // 오디오가 이미 로딩되어 있고 재생 가능한 상태
    if (sound && sound.state() === "loaded" && duration > 0) {
      sound.seek(clickedSeconds, soundId ?? undefined);
      setCurrentTime(clickedSeconds);
    } else {
      // 아직 로딩 안 됐으면 나중에 적용되도록 대기
      setPendingSeek(clickedSeconds);
    }
  }, [clickedSeconds]);

  useEffect(() => {
    const moveHandler = (e: MouseEvent | TouchEvent) => {
      let clientX = 0;

      if (e instanceof MouseEvent) {
        clientX = e.clientX;
      } else if (e instanceof TouchEvent) {
        clientX = e.touches[0].clientX;
      }

      if (!sliderRef.current || !isDragging) return;

      const rect = sliderRef.current.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const ratio = offsetX / rect.width;
      const newTime = Math.max(0, Math.min(duration, ratio * duration));
      setCurrentTime(newTime);
    };

    const upHandler = () => {
      setIsDragging(false);
      if (audioRef.current && duration > 0) {
        audioRef.current.seek(currentTime, soundId ?? undefined);
      } else {
        setPendingSeek(currentTime);
      }
    };

    if (isDragging) {
      window.addEventListener("mousemove", moveHandler);
      window.addEventListener("mouseup", upHandler);

      window.addEventListener("touchmove", moveHandler);
      window.addEventListener("touchend", upHandler);
    }

    return () => {
      window.removeEventListener("mousemove", moveHandler);
      window.removeEventListener("mouseup", upHandler);

      window.removeEventListener("touchmove", moveHandler);
      window.removeEventListener("touchend", upHandler);
    };
  }, [isDragging, duration, currentTime]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
      window.addEventListener("touchmove", handleDragMove);
      window.addEventListener("touchend", handleDragEnd);
    } else {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("touchend", handleDragEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("touchend", handleDragEnd);
    };
  }, [isDragging]);

  // ⏯️ 재생/정지
  // 재생 / 일시정지
  const togglePlay = async () => {
    await Howler.ctx?.resume();

    if (!audioRef.current) {
      audioRef.current = new Howl({
        src: [audioPath],
        html5: true,
        preload: true,
        onload: () => {
          setDuration(audioRef.current!.duration());

          // ⏩ 로딩 이후 대기 중이던 seek 적용
          if (pendingSeek !== null) {
            audioRef.current!.seek(pendingSeek);
            setCurrentTime(pendingSeek);
            setPendingSeek(null);
          }
        },
        onplay: (id) => {
          setIsPlaying(true);
          setSoundId(id);
          requestAnimationFrame(updateProgress);
        },
        onpause: () => setIsPlaying(false),
        onend: () => setIsPlaying(false),
      });
    }

    const sound = audioRef.current;
    const id = soundId;

    if (id && sound.playing(id)) {
      sound.pause(id);
    } else {
      const newId = sound.play();
      setSoundId(newId);
    }
  };

  // 🎯 진행 상태 업데이트
  const updateProgress = () => {
    const sound = audioRef.current;
    const id = soundId ?? undefined;

    if (sound && sound.playing(id)) {
      const time = sound.seek(id);
      if (!isDragging && typeof time === "number") {
        setCurrentTime(time);
      }
      // 💡 중복 방지: 기존 raf 취소 후 재요청
      rafIdRef.current = requestAnimationFrame(updateProgress);
    } else {
      // ❗ 멈춘 경우 루프 종료
      rafIdRef.current = null;
    }
  };

  // 📍 시간 포맷
  const formatTime = (time: number) => {
    const min = Math.floor(time / 60);
    const sec = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${min}:${sec}`;
  };

  // 드래그 중에 실시간 위치 저장
  const handleDragMove = (e: MouseEvent | TouchEvent) => {
    if (!isDragging || !sliderRef.current) return;

    const clientX = e instanceof TouchEvent ? e.touches[0].clientX : e.clientX;

    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const ratio = offsetX / rect.width;
    const newTime = Math.max(0, Math.min(duration, ratio * duration));

    latestDragTimeRef.current = newTime; // ✅ 실시간 저장
    setCurrentTime(newTime); // UI 반영
  };

  // 드래그 끝
  const handleDragEnd = () => {
    setIsDragging(false);
    const seekTime = latestDragTimeRef.current; // ✅ 정확한 값 사용

    if (audioRef.current && duration > 0) {
      audioRef.current.seek(seekTime, soundId ?? undefined);
      setCurrentTime(seekTime); // 상태도 동기화
      requestAnimationFrame(updateProgress);
    } else {
      setPendingSeek(seekTime);
    }
  };

  // 화면 벗어나면 오디오 종료
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.stop();
        audioRef.current.unload();
        audioRef.current = null;
      }

      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
    };
  }, []);

  return (
    <div className="audio-container">
      <button onClick={togglePlay} className="play-btn">
        {isPlaying ? <PauseIcon /> : <StartIcon />}
      </button>
      <span className="date">
        {formatTime(currentTime)} / {formatTime(duration)}
      </span>

      {/* 🎚️ 커스텀 슬라이더 */}
      <div
        ref={sliderRef}
        className="slider-container"
        onMouseDown={(e) => {
          if (e.button === 0) {
            // 좌클릭일 때만
            setIsDragging(true); // 실제 마우스 이동에 따라 드래그 처리
          }
        }}
        onTouchStart={() => setIsDragging(true)}
      >
        <div
          className="slider-progress"
          style={{
            width: `${(currentTime / duration) * 100}%`,
          }}
        />
        <div
          className="slider-handle"
          style={{
            left: `${(currentTime / duration) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};

export default MeasureRecordResultAudio;
