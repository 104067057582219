import { atom } from "recoil";

export const isReviewModifyChangedState = atom<boolean>({
  key: "isReviewModifyChangedState",
  default: false, // 기본값: 변경 없음
});

export const clipboardToastStatus = atom<boolean>({
  key: "clipboardToastStatus",
  default: false,
});
