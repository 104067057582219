import { fetchLoadColumnDetail } from "api/main.home";
import { fetchPreviewReviewDetail } from "api/review";
import { clipboardToastStatus } from "atoms/review.state";
import { LinkIcon } from "cssReset/icon";
import { ReviewDetailProps } from "interface/interface.review";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

interface ModalProps {
  handleModalClose: () => void;
  type?: string;
}

const ModalShareBox = ({ handleModalClose, type }: ModalProps) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<ReviewDetailProps | null>(null);
  const setClipboard = useSetRecoilState(clipboardToastStatus);

  useEffect(() => {
    if (type === "column") {
      loadColumnDetail();
    } else {
      loadPreviewDetail();
    }
  }, [id]);

  const loadPreviewDetail = async () => {
    try {
      if (!id) return; // id가 없으면 함수 실행 중단
      const response = await fetchPreviewReviewDetail(Number(id));

      if (!response?.result) {
        // id가 유효하지 않으면 404 페이지로 이동
        navigate("/404");
        return;
      }

      setData(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const loadColumnDetail = async () => {
    try {
      if (!id) return;

      const response = await fetchLoadColumnDetail(Number(id));

      if (!response?.result) {
        // id가 유효하지 않으면 404 페이지로 이동
        navigate("/404");
        return;
      }

      setData(response.result);
    } catch (error) {
      throw new Error();
    }
  };

  const shareToKakao = () => {
    if (data !== null) {
      Kakao.Share.sendCustom({
        templateId: 119524,
        templateArgs: {
          title: data.title,
          contents: data.contents,
          THU: "https://sombat.co.kr" + data.files[0].accessUrl,
          like: data.reviewLikeCount,
          comment: data.reviewCommentCount,
          view: data.views,

          url:
            type === "column"
              ? `/column/detail/${data.reviewId}`
              : `/review/detail/${data.reviewId}`,
        },
      });
    }
    handleModalClose();
  };

  const shareFaceBook = () => {
    if (data !== null) {
      if (type === "column") {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=http://sombat.co.kr:9090/preview/share/column/${data.reviewId}}`
        );
      } else {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=http://sombat.co.kr:9090/preview/share/review/${data.reviewId}}`
        );
      }
    }
    handleModalClose();
  };

  const linkCopyHandler = async () => {
    try {
      const shareUrl =
        type === "column"
          ? `https://sombat.co.kr/column/detail/${data?.reviewId}`
          : `https://sombat.co.kr/preview/review/detail/${data?.reviewId}`;
      await navigator.clipboard.writeText(shareUrl);
      console.log("링크가 복사되었습니다!");
    } catch (err) {
      console.log("복사에 실패했어요. 다시 시도해 주세요.");
    } finally {
      setClipboard(true);
      handleModalClose();
    }
  };

  return (
    <BoxInner>
      <ShareItemBox>
        <li onClick={shareToKakao}>
          <span className="icon-box">
            <img src="/assets/icon/SNS/kakaotalk/Default.png" alt="" />
          </span>
          카카오톡 공유하기
        </li>
        <li onClick={shareFaceBook}>
          <span className="icon-box">
            <img
              src="/assets/icon/SNS/Facebook/Facebook - Original.png"
              alt=""
            />
          </span>
          페이스북 공유하기
        </li>
        <li onClick={linkCopyHandler}>
          <span className="icon-box">
            <LinkIcon />
          </span>
          공유링크 복사하기
        </li>
      </ShareItemBox>
    </BoxInner>
  );
};

export default ModalShareBox;

const BoxInner = styled.div``;

const ShareItemBox = styled.ul`
  li {
    display: flex;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    .icon-box {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
