import { fetchLoadColumnDetail } from "api/main.home";
import { ContentDTO } from "interface/interface.main.home";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ColumnDetailWrap,
  ContentsBox,
  SliderWrapper,
  SubContentsBox,
  UserInfoBox,
} from "style/column";
import { formatDateTime, formatNumber } from "utils/util";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LeftArrowIcon, RightArrowIcon, ShareIcon } from "cssReset/icon";
import ModalUpLayout from "components/modal/modal.up.layout";
import ModalShareBox from "view/review/children/modal/modal.share.box";
import { useRecoilState } from "recoil";
import { snsModalState } from "atoms/modal.state";

const ColumnDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<ContentDTO | null>(null);
  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호

  const [snsModal, setSnsModal] = useRecoilState(snsModalState);

  useEffect(() => {
    const loadColumnDetail = async () => {
      try {
        const response = await fetchLoadColumnDetail(Number(id));
        setData(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadColumnDetail();
  }, [id]);

  const sliderSetting = {
    infinite: data?.files && data.files.length > 1, // data와 files가 존재할 경우에만 확인
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    rows: 1, // 4개 이상일 경우에만 3개 행을 보여줌
    slidesToScroll: 1,
    prevArrow: <LeftArrowIcon color="#000" />, // 이전 버튼에 아이콘 추가
    nextArrow: <RightArrowIcon color="#000" />, // 다음 버튼에 아이콘 추가
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };

  // sns modla
  const handleModalClose = () => {
    setSnsModal({ isOpen: false, isVisible: false });
  };
  const handleModalOpen = () => {
    setSnsModal({ isOpen: true, isVisible: true });
  };

  return (
    <ColumnDetailWrap>
      <UserInfoBox>
        <div className="profile-img">
          <img src="" alt="" />
        </div>
        <div className="info">
          <span className="user-name">관리자</span>
          {data && <span className="date">{formatDateTime(data?.regDt)}</span>}
        </div>
      </UserInfoBox>
      <ContentsBox>
        <h4 className="title">{data?.title}</h4>
        <p className="contents-text">{data?.contents}</p>
        {data?.files && data.files.length > 0 ? (
          <SliderWrapper>
            <div className="inner">
              <Slider {...sliderSetting}>
                {data.files.map((img, index) => (
                  <div className="img-box" key={img.fileId}>
                    {/* src url 배포시 수정 */}
                    <img src={img.accessUrl} alt={img.realFileName} />
                  </div>
                ))}
              </Slider>
            </div>
            {/* 현재 슬라이드 위치 표시 */}
            <div className="slide-counter">
              {currentSlide} / {data.files.length}
            </div>
          </SliderWrapper>
        ) : (
          ""
        )}
        <SubContentsBox>
          <div className="review-count">
            <span>조회</span>
            {formatNumber(Number(data?.views))}
          </div>
          <div className="icon-box share-btn" onClick={handleModalOpen}>
            <ShareIcon />
          </div>
        </SubContentsBox>
      </ContentsBox>

      {/* 공유하기 모달 */}
      {snsModal.isOpen && (
        <ModalUpLayout
          closedHandler={handleModalClose}
          isVisible={snsModal.isVisible}
        >
          <ModalShareBox handleModalClose={handleModalClose} type="column" />
        </ModalUpLayout>
      )}
    </ColumnDetailWrap>
  );
};

export default ColumnDetail;
