import { idValidHandler } from "api/login";
import { logoutToastStatus } from "atoms/auth.state";
import KakaoSocialLogin from "components/auth/login/kakao.login";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  Container,
  EmailInputWrap,
  ErrorMessage,
  InputBox,
  LinkBox,
  SocialLoginWrap,
  SubmitButton,
} from "style/login";
import { Slide, ToastContainer, toast } from "react-toastify";
import GoogleloginProviderCustom from "components/auth/login/google.login.provider.custom";
import AppleLoginBtn from "components/auth/login/apple.login";

const LoginView = () => {
  const [email, setEmail] = useState(""); // 이메일 상태 관리
  const [idState, setIdState] = useState("");
  const navigate = useNavigate(); // React Router의 useNavigate 사용
  const [logoutState, setLogoutState] = useRecoilState(logoutToastStatus);

  const platform = new URLSearchParams(window.location.search).get("platform");

  useEffect(() => {
    platform && localStorage.setItem("platform", platform); // or 상태로 기억
  }, [platform]);

  useEffect(() => {
    if (logoutState) {
      loadToast();
      setLogoutState(false);
    }
  }, [logoutState]);

  // ios 토큰 받아오기
  useEffect(() => {
    const eventHandler = async () => {
      let fcmToken: string | undefined;

      // ✅ iOS WebView인 경우에만 토큰 요청
      if (window.webkit?.messageHandlers?.getFcmToken) {
        try {
          const tokenPromise = new Promise<string>((resolve) => {
            const handleToken = (event: any) => {
              if (event.detail?.token) {
                console.log("✅ 받은 FCM 토큰:", event.detail.token);
                window.removeEventListener("fcm-token", handleToken);
                resolve(event.detail.token);
              }
            };
            window.addEventListener("fcm-token", handleToken);
          });

          // ✅ iOS에게 토큰 요청
          window.webkit.messageHandlers.getFcmToken.postMessage("request");
          fcmToken = await tokenPromise;
          console.log(fcmToken);
        } catch (error) {
          console.warn("🔥 FCM 토큰 요청 중 오류 발생:", error);
        }
      }
    };

    eventHandler();
  }, []);

  // 로컬 스토리지를 확인하여 로그인 상태 판단
  useEffect(() => {
    const authState = localStorage.getItem("authState");
    const accessToken = localStorage.getItem("accessToken");

    if (authState && accessToken) {
      const { isAuthenticated } = JSON.parse(authState);

      if (isAuthenticated) {
        navigate("/home"); // 로그인 상태라면 /home으로 리다이렉트
        return;
      }
    }

    // authState 또는 accessToken이 없을 경우 /으로 리다이렉트
    navigate("/");
  }, [navigate]);

  // 이메일 형식 검증 함수
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async () => {
    if (!email) {
      setIdState("이메일을 입력해주세요.");
      return;
    }

    if (!isValidEmail(email)) {
      setIdState("유효한 이메일 주소를 입력해주세요.");
      return;
    }

    setIdState(""); // 에러 메시지 초기화

    try {
      const response = await idValidHandler(email);
      if (response?.result) {
        navigate(`/login/password?email=${encodeURIComponent(email)}`);
      } else {
        navigate(`/join?email=${encodeURIComponent(email)}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Enter 키 감지
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEmailSubmit(); // Enter 키 입력 시 버튼 클릭 동작 트리거
    }
  };

  const loadToast = () => {
    toast(`로그아웃 되었습니다.`, {
      position: "bottom-center",
      style: {
        backgroundColor: "rgba(0,0,0,0.83)",
        color: "#fff",
        fontSize: "1.2rem",
        borderRadius: "1rem",
        fontWeight: "500",
        letterSpacing: "-0.06rem",
        justifyContent: "center",
        padding: "0.8rem",
        minHeight: "auto",
        boxShadow: "1px 1px 4px 0 #0000004D",
      },
      transition: Slide,
    });
  };

  return (
    <Container>
      <EmailInputWrap>
        <InputBox>
          <input
            type="text"
            placeholder="이메일 주소를 입력해주세요."
            value={email}
            onChange={(e) => setEmail(e.target.value)} // 이메일 상태 업데이트
            onKeyDown={handleKeyDown} // Enter 키 감지
          />
          <span></span>
        </InputBox>
        {idState && <ErrorMessage>{idState}</ErrorMessage>}

        <SubmitButton
          onClick={handleEmailSubmit}
          disabled={!isValidEmail(email)} // 이메일 유효성 검사 결과에 따라 비활성화
          style={{
            backgroundColor: isValidEmail(email) ? "#225298" : "#cecece",
          }}
          type="button"
          as="button"
        >
          이메일 주소로 계속하기
        </SubmitButton>
      </EmailInputWrap>
      <div className="hr">
        <span>또는</span>
      </div>
      <SocialLoginWrap>
        <KakaoSocialLogin />
        <GoogleloginProviderCustom />
        <AppleLoginBtn />
      </SocialLoginWrap>
      <LinkBox>
        <Link to="/home">가입하지 않고 둘러볼께요</Link>
      </LinkBox>

      <ToastContainer
        hideProgressBar
        closeButton={false}
        autoClose={1000}
        style={{
          padding: "1.6rem 3.2rem 4.7rem",
          borderRadius: "1rem",
        }}
      />
    </Container>
  );
};

export default LoginView;
